import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.section``

const Embed = ({ data }) => {
  return (
    <Container className="campaign-embed">
      <div
        dangerouslySetInnerHTML={{ __html: data.campaign_component_embed_code }}
      />
    </Container>
  )
}

Embed.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Embed
