import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"

const Container = styled.section``

const Image = ({ data }) => {
  return (
    <Container className="campaign-image">
      <Img
        objectFit="contain"
        fluid={data.image.localFile.childImageSharp.fluid}
        title={data.image.alt_text}
        alt={data.image.alt_text}
      />
    </Container>
  )
}

Image.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Image
