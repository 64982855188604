import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"

const Container = styled.section`
  display: flex;
  align-items: center;

  ${(props) =>
    props.colorTheme === `dark` &&
    css`
      color: ${(props) => props.theme.colorPurple};
    `}

  ${(props) =>
    props.colorTheme === `light` &&
    css`
      color: ${(props) => props.theme.colorWhite};
    `}
`

const Avatar = styled.div`
  width: 20%;
  max-width: ${rem(100)};
  border-radius: 50%;
  overflow: hidden;
  margin-right: ${rem(25)};
`

const Meta = styled.div`
  p {
    & + p {
      margin-top: 0.2em;
      opacity: 0.6;
    }
  }
`

const Author = ({ data, theme }) => {
  return (
    <Container className="campaign-author" colorTheme={theme}>
      <Avatar>
        <Img
          objectFit="contain"
          fluid={data.avatar.localFile.childImageSharp.fluid}
          title={data.avatar.alt_text}
          alt={data.avatar.alt_text}
        />
      </Avatar>
      <Meta className="styled styled--leading">
        <p>{data.meta.name}</p>
        {data.meta.role && <p>{data.meta.role}</p>}
        {data.meta.company && <p>{data.meta.company}</p>}
      </Meta>
    </Container>
  )
}

Author.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
}

export default Author
