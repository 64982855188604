import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

const Container = styled.section`
  ${(props) =>
    props.colorTheme === `dark` &&
    css`
      color: ${(props) => props.theme.colorPurple};
    `}

  ${(props) =>
    props.colorTheme === `light` &&
    css`
      color: ${(props) => props.theme.colorWhite};
    `}
`

const Text = ({ theme, data }) => {
  return (
    <Container
      className="campaign-text styled styled--leading"
      colorTheme={theme}
      dangerouslySetInnerHTML={{
        __html: data.content,
      }}
    />
  )
}

Text.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.string,
}

export default Text
