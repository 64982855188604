import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import uuid from "uuid-random"
import hexRgb from "hex-rgb"

import colors from "../../theme/sections/colors"

const formatColorForIframe = (color) => {
  let formatted = color

  if (formatted.startsWith(`#`)) {
    const rgb = hexRgb(color)
    formatted = `${rgb.red},${rgb.green},${rgb.blue}`
  }

  return formatted.replace(/[^\d,]/g, ``)
}

const Container = styled.section`
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
`

const Iframe = styled.iframe`
  width: 100%;
  height: 10em;
`

const Form = ({ data, theme, backgroundColor }) => {
  if (!data || !data.form_URL) return null

  const iframeRef = useRef()
  const iframeIdRef = useRef(uuid())
  const iframeHeightReceivedRef = useRef(false)
  const textColor = theme == `dark` ? colors.colorPurple : colors.colorWhite

  const postMessage = () => {
    if (!iframeRef.current.contentWindow) return

    iframeRef.current.contentWindow.postMessage(
      {
        action: `cintColors`,
        foreground: formatColorForIframe(textColor),
        background: formatColorForIframe(backgroundColor),
      },
      `*`
    )
  }

  const winMessage = (e) => {
    if (
      iframeRef.current &&
      e &&
      e.data &&
      e.data.id == iframeRef.current.name &&
      e.data.height
    ) {
      iframeRef.current.style.height = `${e.data.height}px`

      if (!iframeHeightReceivedRef.current) {
        iframeHeightReceivedRef.current = true
        postMessage()
      }
    }
  }

  useEffect(() => {
    window.addEventListener(`message`, winMessage)

    if (iframeRef.current) {
      postMessage()
      iframeRef.current.addEventListener(`load`, postMessage)
    }

    return () => {
      window.removeEventListener(`message`, winMessage)
    }
  }, [])

  return (
    <Container
      textColor={textColor}
      backgroundColor={backgroundColor}
      className="campaign-form"
    >
      <Iframe
        ref={iframeRef}
        name={iframeIdRef.current}
        src={data.form_URL}
        scrolling="no"
        loading="lazy"
        data-cookieconsent="ignore"
      />
    </Container>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default Form
