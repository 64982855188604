import { css } from "styled-components"
import { rem } from "polished"

const ComponentSpacingStyles = css`
  & > * + * {
    margin-top: ${rem(20)};
  }

  /* TEXT */
  .campaign-text {
    & + .campaign-text {
      margin-top: ${rem(40)};
    }

    & + .campaign-form {
      margin-top: ${rem(60)};
    }

    & + .campaign-image,
    & + .campaign-video {
      margin-top: ${rem(60)};
    }

    & + .campaign-button {
      margin-top: ${rem(30)};
    }

    & + .campaign-author {
      margin-top: ${rem(30)};
    }

    & + .campaign-logos {
      margin-top: ${rem(60)};
    }

    & + .campaign-numbers {
      margin-top: ${rem(40)};
    }
  }

  /* FORM */
  .campaign-form {
    & + .campaign-text {
      margin-top: ${rem(60)};
    }

    & + .campaign-form {
      margin-top: ${rem(80)};
    }

    & + .campaign-image,
    & + .campaign-video {
      margin-top: ${rem(60)};
    }

    & + .campaign-button {
      margin-top: ${rem(60)};
    }

    & + .campaign-author {
      margin-top: ${rem(60)};
    }

    & + .campaign-logos {
      margin-top: ${rem(60)};
    }

    & + .campaign-numbers {
      margin-top: ${rem(60)};
    }
  }

  /* IMAGE / VIDEO */
  .campaign-image,
  .campaign-video {
    & + .campaign-text {
      margin-top: ${rem(40)};
    }

    & + .campaign-form {
      margin-top: ${rem(60)};
    }

    & + .campaign-image,
    & + .campaign-video {
      margin-top: ${rem(20)};
    }

    & + .campaign-button {
      margin-top: ${rem(40)};
    }

    & + .campaign-author {
      margin-top: ${rem(40)};
    }

    & + .campaign-logos {
      margin-top: ${rem(40)};
    }

    & + .campaign-numbers {
      margin-top: ${rem(60)};
    }
  }

  /* BUTTON */
  .campaign-button {
    & + .campaign-text {
      margin-top: ${rem(40)};
    }

    & + .campaign-form {
      margin-top: ${rem(60)};
    }

    & + .campaign-image,
    & + .campaign-video {
      margin-top: ${rem(40)};
    }

    & + .campaign-button {
      margin-top: ${rem(20)};
    }

    & + .campaign-author {
      margin-top: ${rem(60)};
    }

    & + .campaign-logos {
      margin-top: ${rem(60)};
    }

    & + .campaign-numbers {
      margin-top: ${rem(60)};
    }
  }

  /* AUTHOR */
  .campaign-author {
    & + .campaign-text {
      margin-top: ${rem(40)};
    }

    & + .campaign-form {
      margin-top: ${rem(60)};
    }

    & + .campaign-image,
    & + .campaign-video {
      margin-top: ${rem(40)};
    }

    & + .campaign-button {
      margin-top: ${rem(60)};
    }

    & + .campaign-author {
      margin-top: ${rem(40)};
    }

    & + .campaign-logos {
      margin-top: ${rem(60)};
    }

    & + .campaign-numbers {
      margin-top: ${rem(60)};
    }
  }

  /* LOGO WALL */
  .campaign-logos {
    & + .campaign-text {
      margin-top: ${rem(40)};
    }

    & + .campaign-form {
      margin-top: ${rem(60)};
    }

    & + .campaign-image,
    & + .campaign-video {
      margin-top: ${rem(40)};
    }

    & + .campaign-button {
      margin-top: ${rem(40)};
    }

    & + .campaign-author {
      margin-top: ${rem(60)};
    }

    & + .campaign-logos {
      margin-top: ${rem(20)};
    }

    & + .campaign-numbers {
      margin-top: ${rem(60)};
    }
  }

  /* NUMBERS */
  .campaign-numbers {
    & + .campaign-text {
      margin-top: ${rem(60)};
    }

    & + .campaign-form {
      margin-top: ${rem(60)};
    }

    & + .campaign-image,
    & + .campaign-video {
      margin-top: ${rem(60)};
    }

    & + .campaign-button {
      margin-top: ${rem(40)};
    }

    & + .campaign-author {
      margin-top: ${rem(60)};
    }

    & + .campaign-logos {
      margin-top: ${rem(60)};
    }

    & + .campaign-numbers {
      margin-top: ${rem(30)};
    }
  }
`

export { ComponentSpacingStyles }
