import React from "react"
import PropTypes from "prop-types"

import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1px;

  @media ${(props) => props.theme.xxlargeUp} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${(props) => props.theme.largeDown} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${(props) => props.theme.xsmallDown} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Logo = styled.figure`
  background: white;
  padding: 1em 0.2em;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const Logos = ({ data: { logos } }) => {
  if (!logos || !logos.length) return null

  logos = logos.filter((logo) => logo.localFile)

  if (!logos.length) return null

  return (
    <Container className="campaign-logos">
      {logos.map((node, i) => (
        <Logo key={i}>
          <Img
            objectFit="contain"
            fluid={node.localFile.childImageSharp.fluid}
          />
        </Logo>
      ))}
    </Container>
  )
}

Logos.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Logos
