import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import { rem } from "polished"

import Hero from "../components/hero"
import Layout from "../components/layout"
import MetaWp from "../components/meta-wp"
import SiteHeader from "../components/header"
import CampaignColumn from "../components/campaign-page/column"

const Wrapper = styled.div`
  background-color: ${(props) => props.background};
`

const Container = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: ${rem(1680)};
  margin: 0 auto;
  padding: ${rem(56)};
  position: relative;

  @media ${(props) => props.theme.largeDown} {
    flex-direction: column;

    ${(props) =>
      props.flow === `reverse` &&
      css`
        flex-direction: column-reverse;
      `}
  }

  @media ${(props) => props.theme.largeDown} {
    padding: ${rem(30)};
  }

  @media ${(props) => props.theme.xsmallDown} {
    padding: ${rem(20)} ${rem(15)};
  }
`

const CampaignPage = ({ data, pageContext }) => {
  const {
    metaDefaults,
    metaWpGlobal,
    metaWpPage,
    hideFooterSections,
    hero,
    campaign: {
      acf: { campaignColumns, campaignOptions },
    },
    siteMessage,
  } = data
  const { translations, menus } = pageContext

  let backgroundColor = campaignOptions.campaign_page_theme.background

  if (backgroundColor === `custom`)
    backgroundColor = campaignOptions.campaign_page_theme.custom_background

  return (
    <Layout
      translations={translations}
      menus={menus}
      metaDefaults={metaDefaults}
      metaWpGlobal={metaWpGlobal}
      hideFooter={!campaignOptions.campaign_page_footer.show_footer || false}
      hideFooterSections={hideFooterSections.acf}
      siteMessage={siteMessage}
    >
      <MetaWp page={metaWpPage} global={metaWpGlobal} defaults={metaDefaults} />
      {campaignOptions.campaign_page_header.show_header && (
        <SiteHeader
          logo={campaignOptions.campaign_page_header.logo}
          hideNav={!campaignOptions.campaign_page_header.show_navigation}
        />
      )}

      {hero.acf.toggle && <Hero data={hero} showHeader={false} />}

      <Wrapper background={backgroundColor}>
        <Container flow={campaignOptions.campaign_page_mobile_flow}>
          {campaignColumns.map((column, i) => (
            <CampaignColumn key={i} data={column} options={campaignOptions} />
          ))}
        </Container>
      </Wrapper>
    </Layout>
  )
}

CampaignPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default CampaignPage

export const pageQuery = graphql`
  query ($id: String!) {
    ...MetaDefaultsFragment
    ...MetaWpGlobalFragment
    ...MetaWpPageFragment
    ...SiteMessageFragment
    ...HideFooterSectionsPageFragment

    ...HeroPageFragment
    ...CampaignPageFragment
  }
`
