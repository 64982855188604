import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { rem } from "polished"

const Container = styled.section`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.colorTheme === `dark` &&
    css`
      color: ${(props) => props.theme.colorPurple};
    `}

  ${(props) =>
    props.colorTheme === `light` &&
    css`
      color: ${(props) => props.theme.colorWhite};
    `}
`

const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.dimmed &&
    css`
      opacity: 0.4;
    `}

  & + & {
    margin-top: ${rem(25)};
  }
`

const Numbers = ({ data: { rows }, theme }) => {
  if (!rows || !rows.length) return null

  return (
    <Container colorTheme={theme} className="campaign-numbers">
      {rows.map((row, i) => (
        <Row key={i} dimmed={row.dimmed}>
          <p className="styled-h2">{row.number}</p>
          <p className="styled-h3">{row.description}</p>
        </Row>
      ))}
    </Container>
  )
}

Numbers.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
}

export default Numbers
