import React from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

const Container = styled.a`
  font-size: ${rem(18)};
  padding: 0.75em 1.25em;
  display: inline-block;
  text-align: center;

  @media ${(props) => props.theme.mediumDown} {
    padding: 0.6em 1em;
    font-size: ${rem(16)};
  }

  &:hover,
  &:focus {
    opacity: 0.9;
  }

  &:active {
    transform: translateY(1px);
  }

  &[disabled] {
    opacity: 0.5;
  }

  ${`` /* SIZE */}

  ${(props) =>
    props.size === `small` &&
    css`
      padding: 0.6em 1em;
      font-size: ${rem(16)};

      @media ${(props) => props.theme.mediumDown} {
        padding: 0.5em 0.8em;
        font-size: ${rem(14)};
      }
    `}

  ${(props) =>
    props.size === `large` &&
    css`
      padding: 0.6em 1em;
      font-size: ${rem(22)};

      @media ${(props) => props.theme.mediumDown} {
        padding: 0.5em 0.8em;
        font-size: ${rem(18)};
      }
    `}

  ${`` /* COLORS */}

  ${(props) =>
    props.buttonTheme === `custom` &&
    css`
      --foreground: ${(props) => props.text};
      --background: ${(props) => props.background};
    `}

  ${(props) =>
    props.buttonTheme === `auto` &&
    props.pageTheme === `light` &&
    css`
      --foreground: ${(props) => props.theme.colorPurple};
      --background: ${(props) => props.theme.colorWhite};
    `}

  ${(props) =>
    props.buttonTheme === `auto` &&
    props.pageTheme === `dark` &&
    css`
      --foreground: ${(props) => props.theme.colorWhite};
      --background: ${(props) => props.theme.colorPurple};
    `}

  ${`` /* BACKGROUND STYLE */}

  ${(props) =>
    props.backgroundStyle == `filled` &&
    css`
      color: var(--foreground);
      background-color: var(--background);
    `}

  ${(props) =>
    props.backgroundStyle == `hollow` &&
    css`
      color: var(--background);
      background-color: transparent;
      border: 1px solid var(--background);
    `}
`

const Button = ({ data, theme }) => {
  return (
    <Container
      className="campaign-button"
      href={data.link.url}
      target={data.link.target}
      size={data.size}
      backgroundStyle={data.style}
      buttonTheme={data.color}
      pageTheme={theme}
      background={data.background}
      text={data.text}
      dangerouslySetInnerHTML={{
        __html: data.link.title,
      }}
    />
  )
}

Button.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
}

export default Button
