import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import Author from "./author"
import Button from "./button"
import Form from "./form"
import Embed from "./embed"
import Image from "./image"
import Logos from "./logos"
import Numbers from "./numbers"
import Text from "./text"
import Video from "./video"
import { ComponentSpacingStyles } from "./component-spacing"

const Column = styled.article`
  flex: 1;
  max-width: ${rem(760)};

  @media ${(props) => props.theme.largeUp} {
    & + & {
      margin-left: ${rem(50)};
    }
  }

  @media ${(props) => props.theme.xxlargeUp} {
    & + & {
      margin-left: ${rem(100)};
    }
  }

  @media ${(props) => props.theme.mediumDown} {
    padding-top: ${rem(30)};
    padding-bottom: ${rem(30)};
  }

  ${ComponentSpacingStyles}
`

const CampaignColumn = ({ options, data: { campaignComponents } }) => {
  if (
    (!options || !options.length) &&
    (!campaignComponents || !campaignComponents.length)
  )
    return null

  const backgroundColor = options.campaign_page_theme.background
  let textColor = options.campaign_page_theme.text_color

  if (backgroundColor === `#FFFFFF`) textColor = `dark`
  else if (backgroundColor === `#1e0028`) textColor = `light`

  return (
    <Column>
      {campaignComponents.map((component, i) => {
        switch (component.acf_fc_layout) {
          case `campaign_component_text`:
            return (
              <Text
                key={i}
                data={component.campaign_component_text_data}
                theme={textColor}
              />
            )

          case `campaign_component_author`:
            return (
              <Author
                key={i}
                data={component.campaign_component_author_data}
                theme={textColor}
              />
            )

          case `campaign_component_button`:
            return (
              <Button
                key={i}
                data={component.campaign_component_button_data}
                theme={textColor}
              />
            )

          case `campaign_component_image`:
            return (
              <Image key={i} data={component.campaign_component_image_data} />
            )

          case `campaign_component_video`:
            return (
              <Video
                key={i}
                data={component.campaign_component_video_data}
                theme={textColor}
              />
            )

          case `campaign_component_form`:
            return (
              <Form
                key={i}
                data={component.campaign_component_form_data}
                theme={textColor}
                backgroundColor={backgroundColor}
              />
            )

          case `campaign_component_numbers`:
            return (
              <Numbers
                key={i}
                data={component.campaign_component_numbers_data}
                theme={textColor}
              />
            )

          case `campaign_component_logos`:
            return (
              <Logos key={i} data={component.campaign_component_logos_data} />
            )

          case `campaign_component_embed`:
            return (
              <Embed key={i} data={component.campaign_component_embed_data} />
            )

          default:
            return <div key={i}>{component.acf_fc_layout}</div>
        }
      })}
    </Column>
  )
}

CampaignColumn.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
}

export default CampaignColumn
